<template>
  <div class='courses'>
    <layout-header />
    <main class='courses__main base-content-pc'>
      <section class='courses__main__left'>
        <select-bar-setting />
      </section>
      <section class='courses__main__right'>
        <div class='courses__main__right__title'>
          <h3>コース</h3>
        </div>
        <div class='courses__main__right__current'>
          <h5>現在のコース</h5>
          <div>
            <p>{{ setCurrentCourseName }}</p>
            <p>¥{{ setCurrentCoursePrice }}/月</p>
          </div>
        </div>
        <div class='courses__main__right__date' v-if='study.courseInfo.id !== 1'>
          <p>
            {{ setDateTitle }}: {{ study.courseInfo.period }}
          </p>
        </div>
        <div class='courses__main__right__withdrawal' v-if='study.courseInfo.id !== 1'>
          <router-link
            v-if='!this.$store.state.study.courseInfo.subscriptionCancelFlg'
            class='courses__main__right__withdrawal__button'
            :to='{ name: "CourseWithdrawal"}'
          >
            コースを解約される方はこちら
          </router-link>
        </div>
        <div v-else class='courses__main__right__not-subscription'>
          <h5>選択可能なコース</h5>
          <ul class='courses__main__right__not-subscription__list'>
            <li v-for='course in setNotSubscriptionCourseList' :key='course.id'>
              <p>{{ course.name }}</p>
              <button-normal @emitClick='checkoutSubscription(course.type)' :loading='isLoading'>
                <p>¥{{ course.price }} / 月で申し込む</p>
              </button-normal>
            </li>
          </ul>
        </div>
      </section>
    </main>
    <layout-footer />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LayoutHeader from '@/components/layouts/LayoutHeader.vue';
import LayoutFooter from '@/components/layouts/LayoutFooter.vue';
import SelectBarSetting from '@/components/atoms/select/SelectBarSetting.vue';
import ButtonNormal from '@/components/atoms/button/ButtonNormal.vue';
import { loadStripe } from '@stripe/stripe-js';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    SelectBarSetting,
    ButtonNormal,
  },
  data() {
    return {
      isLoading: false,
      stripe: null,
      courseList: [
        {
          id: 1,
          name: 'フリーコース',
          price: '0',
        },
        {
          id: 2,
          name: 'フロントエンドコース',
          price: '9,680',
          type: 2,
        },
        {
          id: 3,
          name: 'バックエンドコース',
          price: '9,680',
          type: 3,
        },
        // {
        //   id: 4,
        //   name: 'フルスタックコース',
        //   price: '14,520',
        //   type: 4,
        // },
      ],
    };
  },
  async mounted() {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
    await this.execLoadCourseInfo();
  },
  computed: {
    setCurrentCourseName() {
      return this.courseList
        .filter((course) => course.id === this.$store.state.study.courseInfo.id)[0].name;
    },
    setCurrentCoursePrice() {
      return this.courseList
        .filter((course) => course.id === this.$store.state.study.courseInfo.id)[0].price;
    },
    setNotSubscriptionCourseList() {
      return this.courseList
        .filter((course) => course.id !== this.$store.state.study.courseInfo.id);
    },
    setDateTitle() {
      return this.$store.state.study.courseInfo.subscriptionCancelFlg
        ? '解約予定日' : '契約更新日';
    },
    ...mapState(['user']),
    ...mapState(['study']),
  },
  methods: {
    loading() {
      this.isLoading = !this.isLoading;
    },
    async execLoadCourseInfo() {
      const result = await this.$store.dispatch('loadCourseInfo');
      if (result === 401) {
        this.$router.push({ name: 'Top' });
      } else if (!result) {
        this.$router.push({ name: 'Error' });
      }
    },
    async checkoutSubscription(type) {
      try {
        this.loading();
        this.$http.defaults.headers.common.Authorization = `Bearer ${this.$store.state.user.token}`;

        // type 2: フロント 3: バック 4: フルスタック
        const { data } = await this.$http.post('/api/auth/customer/checkout/subscription', {
          type,
        });

        this.stripe.redirectToCheckout({ sessionId: data.session.id });
      } catch (e) {
        this.loading();
      }
    },
  },
};
</script>

<style lang="scss">
.courses {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.courses__main {
  margin: 0 auto 120px;
  display: flex;
  max-width: 100%;
}

.courses__main__left {
  width: 25%;
  margin-right: 30px;
}

.courses__main__right {
  width: 75%;
  background-color: $color-white;
  padding: 30px;
}

.courses__main__right__title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  h3 {
    font-size: 24px;
    font-weight: 600;
  }
}

.courses__main__right__current {
  h5 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    margin: 0;
  }
  div {
    display: flex;
    justify-content: space-between;
    padding: 8px 10px;
    align-items: center;
    background-color: $color-gray;
    border-radius: 3px;
    max-width: 350px;
  }
}

.courses__main__right__date {
  margin-top: 5px;
  font-size: 14px;
}

.courses__main__right__withdrawal {
  margin-top: 10px;
}

.courses__main__right__withdrawal__button {
  font-size: 12px;
  color: $color-dark-gray;
  text-decoration: none;
}

.courses__main__right__withdrawal__button:hover {
  text-decoration: underline;
}

.courses__main__right__not-subscription {
  margin-top: 40px;
  h5 {
    font-size: 16px;
  }
}

.courses__main__right__not-subscription__list {
  margin: 0;
  li {
    display: flex;
    justify-content: space-between;
    max-width: 350px;
    align-items: center;
    margin-top: 10px;
  }
}
</style>
